<template id="tmplLogin">
  <div id="divLogin" class="app flex-row align-items-center">
    <div id="divLoginParent" class="container vld-parent">
      <b-row id="rowLoginUser" class="justify-content-center">
        <b-col id="colLoginUser" md="8" lg="5" sm="10" xs="12">
          <b-card-group id="crdGrpLoginUser">
            <b-card id="crdLoginUser" no-body class="p-4">
              <b-card-body id="crdBdyLoginUser">
                <div id="divTFLogo" class="body-logo-container">
                  <img id="imgTFLogo" src="/img/tripfocus.png" class="pb-4" alt="TripFocus" />
                </div>
                <b-row id="rowLoginUserName" v-if="oidcUser">
                  <b-col id="colLoginUserName" cols="12">
                    <h3 id="hdrLoginUserName" class="text-center pb-3">
                      Hello {{ loggedInUser.displayName }}
                    </h3>
                  </b-col>
                  <b-col id="colGoToDashboardBtn" cols="6">
                    <b-button
                      id="btnGoToDashbard"
                      block
                      variant="outline-primary"
                      class="px-4"
                      @click="navigateToDashboard()"
                      >Go to Dashboard</b-button
                    >
                  </b-col>
                  <b-col id="colLogout" cols="6">
                    <b-button
                      id="btnLogout"
                      block
                      variant="outline-danger"
                      class="px-4"
                      @click="logoutSSO()"
                      >Sign out</b-button
                    >
                  </b-col>
                </b-row>
                <b-row id="rowWelcome" v-else>
                  <b-col id="colWelcome" cols="12">
                    <h3 id="hdrWelcome" class="text-center">Welcome to TripFocus!</h3>
                    <p id="pWelecome" class="text-center">
                      Please sign in below to proceed.
                    </p>
                  </b-col>

                  <b-col id="colSignIn" cols="6" offset="3">
                    <b-button
                      id="btnSignIn"
                      block
                      variant="outline-primary"
                      class="px-4"
                      @click="loginSSO()"
                      >Sign in</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import inputValidations from "@/shared/utils/input-validation";
import urls from "../shared/urls";
import { mapGetters, mapActions } from "vuex";

  export default {
  name: "Login.vue",
  components: {
    Loading
  },

  data: () => {
    return {
      username: "",
      password: "",
      showValidation: false,
      validation: "",
      isLoading: false,
      inputValidations: inputValidations,
      urls: urls
    };
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  methods: {
    ...mapActions("oidcStore", ["authenticateOidc", "removeOidcUser"]),
    async loginSSO() {
      this.authenticateOidc();
    },

    async logoutSSO() {
      this.removeOidcUser();
    },
    
    navigateToDashboard() {
      this.$router.replace(sessionStorage.getItem('redirectPath') || '/dashboard');
      //Cleanup redirectPath
      sessionStorage.removeItem('redirectPath');
    },
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("user", ["loggedInUser"])
  }
};
</script> 
